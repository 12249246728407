<template>
  <div v-if="!$_loading_state" class="p-noticeDetail">
    <div class="c-section">
      <div class="p-noticeDetail__header">
        <div class="p-noticeDetail__subtitle">{{ category }}</div>
        <div class="p-noticeDetail__title c-title2 c-title2--mb8">
          {{ title }}
        </div>
        <div class="p-noticeDetail__date">{{ date }}</div>
      </div>
      <div class="p-noticeDetail__img">
        <img v-if="imageUrl" :src="imageUrl" :alt="title" />
        <NoImage v-else />
      </div>
      <div class="p-noticeDetail__content" v-html="content" />
    </div>
  </div>
</template>

<script>
import { SystemDialogMessage, MessageCategoryType } from '@/constants/enums';

export default {
  data: () => ({
    notice: null
  }),

  computed: {
    noticeId() {
      return this.$route.params.id;
    },

    title() {
      return this.notice?.title;
    },

    category() {
      return MessageCategoryType.getTypeName(this.notice?.category_type);
    },

    date() {
      return (
        this.notice &&
        this.$utilities.localizeDate(this.notice?.publish_start_at)
      );
    },

    content() {
      return this.notice?.body;
    },

    imageUrl() {
      return this.notice?.image_url;
    }
  },

  created() {
    this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.$_loading_start();

        const { data } = await this.$repositories('notice').getNotice(
          this.noticeId
        );
        this.notice = data;
      } catch {
        window.location.href = this.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        this.$_loading_stop();
      }
    }
  }
};
</script>
