var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.$_loading_state
    ? _c("div", { staticClass: "p-noticeDetail" }, [
        _c("div", { staticClass: "c-section" }, [
          _c("div", { staticClass: "p-noticeDetail__header" }, [
            _c("div", { staticClass: "p-noticeDetail__subtitle" }, [
              _vm._v(_vm._s(_vm.category)),
            ]),
            _c(
              "div",
              { staticClass: "p-noticeDetail__title c-title2 c-title2--mb8" },
              [_vm._v(" " + _vm._s(_vm.title) + " ")]
            ),
            _c("div", { staticClass: "p-noticeDetail__date" }, [
              _vm._v(_vm._s(_vm.date)),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "p-noticeDetail__img" },
            [
              _vm.imageUrl
                ? _c("img", { attrs: { src: _vm.imageUrl, alt: _vm.title } })
                : _c("NoImage"),
            ],
            1
          ),
          _c("div", {
            staticClass: "p-noticeDetail__content",
            domProps: { innerHTML: _vm._s(_vm.content) },
          }),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }